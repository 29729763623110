import {Routes} from '@angular/router';

export const content: Routes = [
  {
    path: 'general',
    loadChildren: () => import('../../components/general/general.module').then(m => m.GeneralModule)
  },
  {
    path: 'centers',
    loadChildren: () => import('../../components/centers/centers.module').then(m => m.CentersModule)
  },
  {
    path: 'students',
    loadChildren: () => import('../../components/students/students.module').then(m => m.StudentsModule)
  },
  {
    path: 'sessions',
    loadChildren: () => import('../../components/sessions/sessions.module').then(m => m.SessionsModule)
  },
  {
    path: 'modules',
    loadChildren: () => import('../../components/modules/module.module').then(m => m.ModuleModule)
  },
  {
    path: 'departments',
    loadChildren: () => import('../../components/department/department.module').then(m => m.DepartmentModule)
  },
  {
    path: 'exams',
    loadChildren: () => import('../../components/exam/exam.module').then(m => m.ExamModule)
  },
  {
    path: 'results',
    loadChildren: () => import('../../components/result/result.module').then(m => m.ResultModule)
  },
  {
    path: 'docs',
    loadChildren: () => import('../../components/docs/docs.module').then(m => m.DocsModule)
  },
  {
    path: 'accounts',
    loadChildren: () => import('../../components/accounts/accounts.module').then(m => m.AccountsModule)
  },
  {
    path: 'access',
    loadChildren: () => import('../../components/access/access.module').then(m => m.AccessModule)
  },
  {
    path: 'support',
    loadChildren: () => import('../../components/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'issues',
    loadChildren: () => import('../../components/issues/issues.module').then(m => m.IssuesModule)
  },
  {
    path: 'notify',
    loadChildren: () => import('../../components/notification/notification.module').then(m => m.NotificationModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('../../components/profile/profile.module').then(m => m.ProfileModule)
  },
];
